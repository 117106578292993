import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "addresses-list",
  computed:{
    ...mapGetters({
      user:`profile/user`
    })
  },
  created() {
    if(this.user)
    this.fetchUser()
  },
  methods:{
    ...mapMutations({
      changePopupAddAddress: `profile/CHANGE_POPUP_ADD_ADDRESS`,
      changeEditAddress: `order/CHANGE_EDIT_ADDRESS_POPUP`,
      setEditAddressData:`order/SET_EDIT_ADDRESS_DATA`,
      changeUserInfo: 'order/CHANGE_USER_INFO',
      changePopupAddressesList: `basket/CHANGE_POPUP_ADDRESSES_LIST`,
    }),
    ...mapActions({
      fetchUser: `profile/FETCH_USER_DATA`
    }),
    setAddress(address){
      console.log('method',address)
      this.changeUserInfo(address)
      setTimeout(()=>{
        this.changePopupAddressesList(false)
      },200)

    },
    edit(address){
      this.setEditAddressData(address)
      this.changeEditAddress(true)

    }
  }
}